<template>
  <form class="findPasswordStep1" id="signupForm" @submit.prevent="onSubmit(userId)">
    <p class="etext">비밀번호를 잊으셨나요?<br/>아이디를 입력하신 후 확인 버튼을 누르세요.</p>
    <fieldset>
      <label class="k-form-field">
        <input  type="text"
                ref="userIdRef"
                class="k-textbox"
                v-model.trim="userId"
                placeholder="아이디를 입력해 주세요"
                autofocus
        />
      </label>
      <p class="lnote"><span class="k-icon"></span></p>
      <input type="submit" value="확인" class="mid_btn orange login_btn" style="cursor: pointer;">
    </fieldset>
    <p class="balink">
      <router-link to="/login">로그인화면으로 돌아가기</router-link>
    </p>
  </form>
</template>


<script>
  export default {
    name: 'FindPasswordStep1',
    methods: {
      onSubmit (userId) {
        const vm = this

        if(!vm.userId) {
          kendo.alert('아이디를 입력해 주세요').bind('close', function() {
            vm.$refs.userIdRef.focus()
          })
          return false
        }

        vm.$store.dispatch('FETCH_CONFIRM_ID', { userId }).then(data => {
          vm.$emit('child', data.resultData)
        })
      },
    },
    data () {
      return {
        userId: null,
      }
    },
  }

</script>
